import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { purgeAll } from '@store/actions';
import styles from './Sidebar.module.scss';
import imgSettingIcon from '@images/home/ic_settings_24.svg';
import teacherIcon from '@images/home/account.svg';
import studentIcon from '@images/home/account_school.svg';
import adminIcon from '@images/home/account_start.svg';
import guideIcon from '@images/home/guide.svg';
import { getSchoolPromise } from "@store/actions";

const Sidebar = (props) => {
  const { auth } = props;
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);
  const groups = useSelector((state) => state.groups);
  const userSchoolInfo = useSelector((state) => state.schools.byId.name);
  const history = useHistory();
  const schoolData = useSelector((state) => state.schools.byId[user.schoolId]);
  const managerlist = useSelector((state) => state.clients.allManagerTeacher); // manager
  const [info, setInfo] = useState("");
  let school_name= "";
  let school_division = "";

  if(schoolData != undefined) {
    school_name = schoolData.name;
    school_division = schoolData.division;
  };

  useEffect(()=>{
    const foundUser = managerlist?.filter((userInfo) => userInfo.client.clientId === user.clientId);
    setInfo(foundUser);
  },[managerlist]);

  const onPurgeClick = () => {
    if (window.confirm('로그아웃 하시겠어요?')) {
      dispatch(purgeAll());
      history.replace('/auth');
    }
  };
  const onPressGuide = () => {
    history.replace('/guide');
  };
  const onPressTeacher = () => {
    history.replace('/teacher');
  };
  const onPressStudent = () => {
    history.replace('/student');
  }
  const onPressManager = () => {
    history.replace('/manager');
  };
  const onPressSettings = () => {
    history.replace('/settings');
    return;
  };
  return (
    <div className={styles['side-screen']}>
      <div className={styles['inner-side']}>
        <div className={styles['side-bar-line']}>
          <div className={styles['user-division']}>{"[" + school_division + "]"}</div>
          <div className={styles['user-school']}>{school_name}</div>
          <div className={styles['user-info']}>
            {user?.userName}
            {/* {info[0]?.client?.userName} */}
          </div>
          <div className={styles['user-mail']}>
            {user?.email}
            {/* {info[0]?.account?.email} */}
          </div>
        </div>
        <div>
          <div className={styles['home-block']} onClick={() => onPressGuide()}>
            <img className={styles['img-set']} src={guideIcon} alt="teacher" /> 
            <div className={styles['span-sidebar']}>사용 가이드</div>
          </div>
          <div className={styles['student-block']} onClick={() => onPressTeacher()}>
            <img className={styles['img-set']} src={teacherIcon} alt="teacher" />
            <div className={styles['span-sidebar']}>선생님 계정 관리</div>
          </div>
          {/* <div className={styles['student-block']} onClick={() => onPressStudent()}>
            <div className={styles['span-sidebar']}>학생 계정 관리</div>
          </div> */}
          <div className={styles['student-block']} onClick={() => onPressManager()}>
            <img className={styles['img-set']} src={adminIcon} alt="teacher" /> 
            <div className={styles['span-sidebar']}>관리자 계정 관리</div>
          </div>
        </div>
        <div className={styles['bottom-container']}>
          <div
            className={styles['setting-block']}
            onClick={() => onPressSettings()}
          >
            <img
              className={styles['img-set']}
              src={imgSettingIcon}
              alt="home"
            />
            <div className={styles['span-sidebar']}>설정</div>
          </div>
          <div className={styles['bottom-logout-container']}>
            <button className={styles['buttons']} onClick={() => onPurgeClick()}>
              로그아웃 
            </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
