import { call, put, takeLeading } from 'redux-saga/effects'
import {
  createTeacherAdminSuccess,
  createTeacherAdminFail,
  createTeacherAdminPromise,

  createStudentAdminSuccess,
  createStudentAdminFail,
  createStudentAdminPromise,

  getAllTeachersAdminPromise,
  getAllTeachersAdminSuccess,
  getAllTeachersAdminFail,

  getAllManagerTeachersAdminPromise,
  getAllManagerTeachersAdminSuccess,
  getAllManagerTeachersAdminFail,

  getAllStudentsAdminPromise,
  getAllStudentsAdminSuccess,
  getAllStudentsAdminFail,

  findTeachersAdminPromise,
  findTeachersAdminSuccess,
  findTeachersAdminFail,

  updateTeacherInfoAdminPromise,
  updateTeacherInfoAdminSuccess,
  updateTeacherInfoAdminFail,

  deleteClientAdminPromise,
  deleteClientAdminSuccess,
  deleteClientAdminFail,

  stopClientAdminPromise,
  stopClientAdminSuccess,
  stopClientAdminFail,

  reuseClientAdminPromise,
  reuseClientAdminSuccess,
  reuseClientAdminFail,

  createManagerTeacherAdminSuccess,
  createManagerTeacherAdminFail,
  createManagerTeacherAdminPromise,
} from '@store/actions'
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* createStudentAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.createStudentAdmin, newClient);

      yield put(createStudentAdminSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(createStudentAdminFail(error));
    }
  });
}

function* createTeacherAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.createTeacherAdmin, newClient);

      yield put(createTeacherAdminSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(createTeacherAdminFail(error));
    }
  });
}

// Teacher
function* getAllTeachersAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const response = yield call(api.getAllTeachersAdmin, action.payload); 
      yield put(getAllTeachersAdminSuccess(response.data.accountClients)); 
      return yield response.data;
    } catch (error) {
      console.log('[getAllTeachersAdminPromiseHandler]', error);
      yield put(getAllTeachersAdminFail(error));
    }
  });
}

// Manager
function* getAllManagerTeachersAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const response = yield call(api.getAllManagerTeachersAdmin, action.payload);
      yield put(getAllManagerTeachersAdminSuccess(response.data.accountClients));
      return yield response.data;
    } catch (error) {
      console.log('[getAllManagerTeachersAdminPromiseHandler]', error);
      yield put(getAllManagerTeachersAdminFail(error));
    }
  });
}

// Student
function* getAllStudentsAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const response = yield call(api.getAllStudentsAdmin, action.payload);
      //console.log("after getAllStudents", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(getAllStudentsAdminSuccess(response.data.accountClients));
      // yield put(signInSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getAllStudentsAdminFail(error));
    }
  });
}

function* findTeachersAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      //console.log("before findTeacher", action.payload);
      const searching = action.payload;
      const response = yield call(api.findTeachersAdmin, searching);
      //console.log("after findTeacher", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(findTeachersAdminSuccess(response.data));
      // yield put(signInSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(findTeachersAdminFail(error));
    }
  });
}


function* updateTeacherInfoAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      //console.log("before updateTeacher", action.payload);
      const newClient = action.payload;
      const response = yield call(api.updateTeacherInfoAdmin, newClient);
      //console.log("after updateTeacher", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(updateTeacherInfoAdminSuccess(response.data));
      // yield put(signInSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(updateTeacherInfoAdminFail(error));
    }
  });
}

function* deleteClientAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      //console.log("before delete client", action.payload);
      const newClient = action.payload;
      const response = yield call(api.deleteClientAdmin, newClient);
      //console.log("after delete client", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(deleteClientAdminSuccess(response.data));
      // yield put(signInSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(deleteClientAdminFail(error));
    }
  });
}

function* stopClientAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.stopClientAdmin, newClient);
      //console.log("after stop client", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(stopClientAdminSuccess(response.data));
      // yield put(signInSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(stopClientAdminFail(error));
    }
  });
}

function* reuseClientAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      const newClient = action.payload;
      const response = yield call(api.reuseClientAdmin, newClient);
      //console.log("after reuse client", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(reuseClientAdminSuccess(response.data));
      // yield put(signInSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(reuseClientAdminFail(error));
    }
  });
}

function* createManagerTeacherAdminPromiseHandler(action) {
  yield call(implementPromiseAction, action, function* () {
    try {
      //console.log("before createManagerTeacher", action.payload);
      const newClient = action.payload;
      const response = yield call(api.createManagerTeacherAdmin, newClient);
      //console.log("after createManagerTeacher", response.data);
      //yield call(api.setToken, userInfo.token);
      //yield call(api.storeStorageData, '@user', userInfo);
      yield put(createManagerTeacherAdminSuccess(response.data));
      // yield put(signInSuccess(response.data));
      return yield api.unpackClient(response.data);
    } catch (error) {
      yield put(createManagerTeacherAdminFail(error));
    }
  });
}

function* clientSaga() {
  yield takeLeading(createStudentAdminPromise, createStudentAdminPromiseHandler);
  yield takeLeading(createTeacherAdminPromise, createTeacherAdminPromiseHandler);
  yield takeLeading(getAllTeachersAdminPromise, getAllTeachersAdminPromiseHandler);
  yield takeLeading(getAllManagerTeachersAdminPromise, getAllManagerTeachersAdminPromiseHandler);
  yield takeLeading(getAllStudentsAdminPromise, getAllStudentsAdminPromiseHandler);
  yield takeLeading(findTeachersAdminPromise, findTeachersAdminPromiseHandler);
  yield takeLeading(updateTeacherInfoAdminPromise, updateTeacherInfoAdminPromiseHandler);
  yield takeLeading(deleteClientAdminPromise, deleteClientAdminPromiseHandler);
  yield takeLeading(stopClientAdminPromise, stopClientAdminPromiseHandler);
  yield takeLeading(reuseClientAdminPromise, reuseClientAdminPromiseHandler);
  yield takeLeading(createManagerTeacherAdminPromise, createManagerTeacherAdminPromiseHandler);
}


export default clientSaga;