import React, { useEffect, useState } from "react";
import SubmitButton from "@components/SubmitButton";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrograms,
  teacherSignUpPromise,
  signInPromise,
} from "@store/actions";
import * as api from "@api";
import classNames from "classnames";
import styles from "./index.module.scss";
import imgHandWave from "@assets/images/auth-default/img_waving_hand.svg";
import { getStorageData } from "@api";
import SidebarAuth from "@components/Layout/SidebarAuth";

const ToggleLoginSignup = (props) => {
  const { isSignup, setIsSignup } = props;
  const text = isSignup ? "이미 계정이 있으신가요?" : "처음 사용이신가요?";
  const toggleBtnText = isSignup ? "로그인하기" : "가입 신청하기";
  const control = useSelector((state) => state.control);
  const toggleBtn = (
    <div
      className={styles["toggle-btn"]}
      onClick={() => {
        setIsSignup(!isSignup);
      }}
    >
      {toggleBtnText}
    </div>
  );
  console.log("[Auth :]", control);
  return (
    <div className={styles["toggle-login-container"]}>
      <span className={styles["toggle-login-signup"]}>{text}</span>
      {toggleBtn}
    </div>
  );
};

const SignIn = (props) => {
  const [loginBtnText, setLoginBtnText] = useState("로그인하기");
  const [isSignup, setIsSignup] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const loginText = "로그인하기";
  const signupText = "사용신청";

  useEffect(() => {
    getStorageData("@user").then((user_raw) => {
      const user = JSON.parse(user_raw);
      // console.log("user : ", user);
      if (user !== null) {
        setLoginBtnText(loginText);
        setIsSignup(false);
      } else {
        setLoginBtnText(loginText);
        setIsSignup(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isSignup) {
      setLoginBtnText(loginText);
    } else {
      setLoginBtnText(loginText);
    }
  }, [isSignup]);

  const isEmailvalid = (email) => {
    const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (!email || regex.test(email) === false) {
      console.log("entered email: ", email);
      alert("이메일을 입력해주세요!");

      return false;
    }
    return true;
  };

  useEffect(() => {
    if ( user.state === "REGISTERED_CERT" ) {
      console.log("RESULT OF USEEFFECT", user);
      // redirect to auth manager
      //history.replace("/home");
      //history.replace("/auth-manager");
      // window.location.href = "/auth-manager";
      alert("_CERT USER");
      throw new Error("login failed _Cert");
      return;
    } else 
    if (
      // user.state === "REGISTERED_APPROVED" ||
      // user.state === "REGISTERED_CERT" ||
      // user.state === "REGISTERED_PENDING" ||
      // user.state === "REGISTERED_GOOGLEFORMS" || 
      user.state === "REGISTERED_APPROVED" &&
      user.role === "ADMIN"
    ) {
      console.log("RESULT OF USEEFFECT", user);
      // redirect to auth manager
      history.replace("/home");
      //history.replace("/auth-manager");
      // window.location.href = "/auth-manager";
    } else if (user.role === "TEACHER" && user.role === "STUDENT") {
      console.log('ssssssssssss어드민아닌계정')
    }
  }, [user.state]);

  const onSubmit = (e) => {
    console.log("submit email");
    const email = e.target.email.value;
    const email_trim = email.replace(/ /gi, "");
    const password = e.target.password.value;
    e.preventDefault();
    if (!isEmailvalid(email_trim)) {
      return;
    }
    else {
      if (password === "") {
        alert("비밀번호를 입력해 주세요!");
        return;
      }
      dispatch(
        signInPromise({
          email: email_trim,
          password: password,
          role:"ADMIN"
        })
      ).then((clientInfo) => {
          if (clientInfo === undefined || clientInfo.role === "TEACHER" || clientInfo.role === "STUDENT" || clientInfo.state === "DELETED") {
            throw new Error("login failed");
          }
          // console.log("client info : ", clientInfo);
          history.replace("/home");
          //history.replace("/auth-manager");
        })
        .catch((error) => {
          console.log(error);
          alert(`로그인에 실패했습니다.
이메일 또는 비밀번호를 확인해주세요.`);
        });
    }
    console.log("confirm user", user);
    // <Redirect to="/auth-manager" email={email} />;
  };

  const Intro = {
    title: "관리자 선생님의 방문을 환영합니다!",
  };

  return (
    <div className={styles["container"]}>
      <SidebarAuth />
      <div className={styles["base-ground"]}>
        <div className={styles["Span-greeting"]}>{Intro.title}</div>
        <div className={styles["form-container"]}>
          <form onSubmit={onSubmit}>
            <input
              type="text"
              name="email"
              placeholder="이메일"
              className={styles["input-form"]}
            />
            <input
              type="password"
              name="password"
              placeholder="비밀번호"
              className={classNames(
                styles["input-form"],
              )}
            />
            <input
              type="submit"
              name="submit"
              value={loginBtnText}
              className={styles["submit-btn"]}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
