import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '@components/Layout/Sidebar';
import { setToken } from '@api';
import styles from './index.module.scss';
import CardStudentInfo from '@components/Card/CardStudentInfo';
import useInput from '../../hooks/useInput';
import {
  getAllStudentsAdminPromise,
  getMembershipPromise,
} from '@store/actions';
import InviteStudentModal from '@components/Modal/InviteStudentModal';
import { selectMeaninglessGroup,selectAllMyGroups } from '@store/selectors';
import { selectAllMemberships } from '@store/membership/selector';

const Student = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const list = useSelector((state) => state.clients.allStudent);
  const [update, setUpdate] = useState();
  const [searchInput, onChangeSearchInput] = useInput('');
  const [sortTag, setSortTag] = useState("upName"); //upNum,downNum,upName,downName
  const [sortAllmyStudent, setsortAllmyStudent] = useState(list);
  const schoolData = useSelector((state) => state.schools.byId[user.schoolId]);
  const [show, setShow] = useState(false);
  // const myGroups = useSelector((state) => selectAllMyGroups(state));
  // const meaninglessGroup = useSelector((state) => selectMeaninglessGroup(state));
  const myMembership = useSelector((state) => selectAllMemberships(state, user));
  // console.log('[Student]', myMembership); 
  // getaccountschoolbygroup

  var studentNum = list.length;

  var schoolMaxData = 0;
  if(schoolData !== undefined && schoolData.maxStudentAccount !== null) {
   schoolMaxData = schoolData.maxStudentAccount;
  };

  useEffect(() => {
    if (sortTag === "upNum") {
      // console.log("눌렸다", sortTag);
      const sortUpGradeStudent = list.sort(function (a, b) {
        if (a.grade === b.grade) {
          if (a.class_name < b.class_name) {
            return -1;
          } else if (a.class_name > b.class_name) {
            return +1;
          } else {
            if (parseInt(a.student_number) < parseInt(b.student_number)) {
              return -1;
            } else if (parseInt(a.student_number) > parseInt(b.student_number)) {
              return +1;
            } else return 0;
          }
        } else return a.grade - b.grade;
      });
      setsortAllmyStudent(sortUpGradeStudent);

    } else if (sortTag === "downNum") {
      // console.log("눌렸다", sortTag);
      const sortDownGradeStudent = list.sort(function (a, b) {
        if (a.grade === b.grade) {
          if (a.class_name < b.class_name) {
            return +1;
          } else if (a.class_name > b.class_name) {
            return -1;
          } else {
            if (parseInt(a.student_number) < parseInt(b.student_number)) {
              return +1;
            } else if (parseInt(a.student_number) > parseInt(b.student_number)) {
              return -1;
            } else return 0;
          }
        } else return b.grade - a.grade;
      });
      setsortAllmyStudent(sortDownGradeStudent);

    } else if (sortTag === "upName") {
      // console.log("눌렸다", sortTag);
      const sortUpNameStudent = list.sort(function (a, b) {
        if (a.username < b.username) {
          return -1;
        } else if (a.username > b.username) {
          return 1;
        } else return 0;
      });
      // console.log('upName', sortUpNameStudent);
      setsortAllmyStudent(sortUpNameStudent);
    } else if (sortTag === "downName") {
      // console.log("눌렸다", sortTag);
      const sortDownNameStudent = list.sort(function (a, b) {
        if (a.username < b.username) {
          return +1;
        } else if (a.username > b.username) {
          return -1;
        } else return 0;
      });
      // console.log('downname', sortDownNameStudent);
      setsortAllmyStudent(sortDownNameStudent);
    }
  }, [sortTag, sortAllmyStudent]);

  useEffect(() => { //삭제 후 바로 반영되도록
    return setsortAllmyStudent(list);
  }, [list]);

  const onSearch = useCallback(() => {
    if (searchInput === null || searchInput === '') {
      setUpdate(list);
    } else {
      const result = list.filter(list => list.client.userName.includes(searchInput) || list.client.studentNumber.includes(searchInput));
      setUpdate(result);
    }
  }, [searchInput]);

  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      onSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  useEffect(() => {
    if (user.token !== undefined && user.token !== null) {
      setToken(user.token);
    }
  }, []);

  useEffect(() => {
    dispatch(getMembershipPromise(user.clientId))
      .then((membershipsData) => {
        if (membershipsData === undefined) {
          throw new Error('already signed up!');
        }
      })
      .catch((error) => {
        console.error('[getMembershipPromise]', error);
      });
  }, []);

  useEffect(() => {
    dispatch(getAllStudentsAdminPromise(user.schoolId))
      .then((studentsData) => {
        if (studentsData === undefined) {
          throw new Error('already signed up!');
        }
      })
      .catch((error) => {
        console.error('[getAllStudentsAdminPromise]', error);
      });
  }, []);

  return (
    <div className={styles['main-container']}>
      <Sidebar auth={true} />

      <div className={styles['top-container']}>
        <div className={styles['box']}>
          <div className={styles['title-text']}>
            <a href='/student'>학생 계정 관리 (사용 {list.length}명 / 최대가능 {schoolMaxData}명)</a>
          </div>
          <div className={styles['top-container-list']}>
            <input
              className={styles["member-search"]}
              type="text"
              placeholder="학생 이름 또는 학번"
              value={searchInput}
              onChange={onChangeSearchInput}
              onKeyPress={handleOnKeyPress}
            />
            <button
              onClick={onSearch}
              type='submit'
              className={styles['search-filter']}>
              검색
            </button>
          </div>
        </div>
      </div>

      <div className={styles['bottom-container']}>
        <div className={styles['bottom-container-list']}>
          <div className={styles['userName']}>이름{' '}
            {/* <button onClick={() => {
              if (sortTag === "upName") setSortTag("downName");
              else setSortTag("upName");
              console.log("눌렸니?>", sortTag);
            }}>{sortTag === "upName" ? "🔻" : sortTag === "downName" ? "🔺" : "➖"}</button> */}
          </div>
          <div className={styles['userEmail']}>학번{' '}
            {/* <button onClick={()=>{
              if(sortTag === "upNum") setSortTag("downNum");
              else setSortTag("upNum");
              console.log("눌렸니?>",sortTag);
            }}>{sortTag === "upNum" ?  "🔺" : sortTag === "downNum" ? "🔻" : "➖"}</button> */}
          </div>
          <div className={styles['userState']}>계정상태</div>
          <div className={styles['userAdd']}>
            <button
              className={styles['userInvite-button']}
              onClick={() => {
                if (studentNum >= schoolMaxData) {
                  alert('최대 ' + schoolMaxData + '명의 학생만 등록할 수 있습니다. 계정 추가 신청은 문의부탁드립니다.');
                  console.log("user add cancelled");
                  return;
                }
                setShow(true);
              }}
            >
              학생 등록하기
            </button>
          </div>
        </div>
        {update ?
          update.map((data) => (
            <CardStudentInfo
              key={data.account.accountId}
              user_id={data.account.accountId}
              user_state={data.client.state}
              userName={data.client.userName}
              studentNum={data.client.studentNumber}
              school_id={data.client.schoolId}
            />
          ))
          : sortAllmyStudent.map((data) => (
            <CardStudentInfo
              key={data.account.accountId}
              user_id={data.account.accountId}
              user_state={data.client.state}
              userName={data.client.userName}
              studentNum={data.client.studentNumber}
              school_id={data.client.schoolId}
            />
          ))
        }
        <br />
      </div>
      <InviteStudentModal show={show} setShow={setShow} 
      school_id={user.schoolId}
      />
    </div>
  );
};

export default Student;
