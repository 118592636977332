import React, { useState } from 'react';
import styles from './CardStudentInfo.module.scss';
import PersonIcon from '@mui/icons-material/Person';
import InfoStudentUpdateModal from '@components/Modal/InfoStudentUpdateModal';
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientAdminPromise,
  getAllStudentsAdminPromise,
  stopClientAdminPromise,
  reuseClientAdminPromise,
} from '@store/actions';

const CardStudentInfo = ({ user_id, user_state, userName, studentNum, school_id }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [userStateText, setUserStateText] = useState(user_state === "REGISTERED_APPROVED" ? "활성" : "정지");

  const onState = () => {
    if (user_state === "REGISTERED_APPROVED") {//활성 상태일떄
      dispatch(stopClientAdminPromise({ accountId: user_id, schoolId: school_id, state: 'REGISTERED_CERT' }))
        .then((result) => {
          console.log("상태 stop 됐는지 확인", result);
          setUserStateText(result.state === "REGISTERED_APPROVED" ? "활성" : "정지");
          dispatch(getAllStudentsAdminPromise(school_id))
            .then((studentsData) => {
              if (studentsData === undefined) {
                throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
    } else {//정지 상태일때
      dispatch(reuseClientAdminPromise({ accountId: user_id, schoolId: school_id, state:'REGISTERED_APPROVED' }))
        .then((result) => {
          console.log("상태 다시 활성화 됐는지 확인", result);
          setUserStateText(result.state === "REGISTERED_APPROVED" ? "활성" : "정지");
          dispatch(getAllStudentsAdminPromise(school_id))
            .then((studentsData) => {
              if (studentsData === undefined) {
                throw new Error('already signed up!');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
    }
  }

  const onDelete = () => {
    if (window.confirm("해당 학생 계정을 삭제하시겠습니다? 삭제 시 복구 불가능합니다.")) {
      navigateToDeleteStudent();
    } else {
      console.log("학생삭제취소");
    }
  };

  const navigateToDeleteStudent = () => {
    dispatch(deleteClientAdminPromise({ id: user_id }))
      .then((result) => {
        console.log(result);
        alert("삭제하였습니다.");
        dispatch(getAllStudentsAdminPromise(school_id))
          .then((studentData) => {
            if (studentData === undefined) {
              throw new Error('already signed up!');
            }
          })
          .catch((error) => {
            console.log(error);
          })
      })
  };

  return (
    <>
      <div className={styles['main-container']}>
        <div className={styles['main-container-userName-box']}>
          <div className={styles['main-container-img']}>
            <PersonIcon sx={{ fontSize: 50 }} />
          </div>
          <div className={styles['main-container-userName']}>
            <div>{userName}</div>
          </div>
        </div>
        <div className={styles['email-container']}>
          {studentNum}
        </div>
        <div className={styles['main-container-accountState']}>
          <button
            onClick={onState}
            className={user_state === "REGISTERED_APPROVED" ? styles['state-button'] : styles['state-button2']}
          >{userStateText}</button>
        </div>
        <div className={styles['button-container']}>
          <button
            onClick={() => setShow(true)}
            className={styles['update-button']}
          >수정</button>
          <button
            onClick={onDelete}
            className={styles['delete-button']}
          >삭제</button>
        </div>
      </div>
      <InfoStudentUpdateModal
        show={show}
        setShow={setShow}
        propUser_id={user_id}
        propUsername={userName}
        propStudentNum={studentNum}
      />
    </>
  );
};

export default CardStudentInfo;
