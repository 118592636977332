import {
  getBackend,
  getBackendAlt,
  postBackend,
  postBackendAlt,
  patchBackend,
  deleteBackend,
  patchBackendAlt,
} from './Backend';

/*
 * Description:
 * postBackendAlt is related with new Django API
 * postBackend is related with new Ktor API
 */

//// Account & Client
///// User specific start
// signUp or register
// make anonymous true

// signIn for 
// make anonymous true
export const signIn = async (userData) => {
  return await postBackend('signIn/email', userData, true);
};

export const createTeacherAdmin = async (teacherAccount) => {
  return await postBackend('account?role=TEACHER', teacherAccount);
};

export const createStudentAdmin = async (studentAccount) => {
  return await postBackend('account?role=STUDENT', studentAccount);
}

export const createManagerTeacherAdmin = async (teacherAccount) => {
  return await postBackend('account?role=ADMIN', teacherAccount);
};

export const getAllTeachersAdmin = async (schid) => {
  return await getBackend('account?schoolId='+schid); 
};

export const getAllManagerTeachersAdmin = async (schid) => {
  return await getBackend('account?schoolId='+schid);
};

export const getAllStudentsAdmin = async (schid) => {
  return await getBackend(`account?schoolId=${schid}`);
};

export const findTeachersAdmin = async (search) => {
  return await postBackend('client/find/teacher/admin/', search);
};

export const deleteClientAdmin = async (clientId) => {
  var new_payload={accountId: clientId.id};
  return await deleteBackend('account/'+ clientId.id, new_payload);
};

export const stopClientAdmin = async (clientId) => {
   var profile= clientId;
   return await patchBackend('account', profile);
};

export const reuseClientAdmin = async (clientId) => {
  var profile= clientId;
   return await patchBackend('account', profile);
};

export const updateUserInfoAdmin = async (profile) => {
  return await patchBackend('account', profile);
};

export const updateTeacherInfoAdmin = async (profile) => {
  return await patchBackend('account', profile);
};

export const registerTeacher = async (newUser) => {
  return await postBackend('account/teacher', newUser, true);
};

// membership
export const getMembership = async (clientId) => {
  return await getBackend(`membership?clientId=${clientId}`);
};

export const getMembershipsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const createMembership = async (membership) => {
  return await postBackend('membership', membership);
};

export const findPasswordClient = async (password_payload) => {
  return await patchBackend('account?role=ADMIN', password_payload);
};

/////// group
export const createGroup = async (group) => {
  return await postBackendAlt('group/create/', group);
};

export const deleteGroup = async (groupId) => {
  return await postBackendAlt('group/delete/', groupId);
};

export const findGroup = async (groupName) => {
  return await getBackendAlt(`group/find/?name=${groupName}`);
};

export const getGroup = async (groupId) => {
  return await getBackendAlt(`group?groupId=${groupId}`);
};

export const getGroupStudents = async (classroomId) => {
  return await getBackendAlt(`group/get/students/?id=${classroomId}`);
};

export const getAllGroup = async () => {
  return await getBackendAlt('group/get/all/');
};

export const getMyGroups = async () => {
  return await getBackendAlt('group/get/list/');
};

export const getGroups = async (groupIds) => {
  return await postBackendAlt('group/get/multiple/', { ids: groupIds });
};

export const getGroupByInviteCode = async (inviteCode) => {
  return await getBackend(`group?inviteCode=${inviteCode}`, true);
};

export const inviteGroupStudents = async (groupId, studentIds) => {
  return await postBackendAlt('group/invite/', {
    id: groupId,
    student_ids: studentIds,
  });
};

export const signUpStudents = async (group, students) => {
  return await postBackendAlt('group/signup/students/', {
    group: group,
    students: students,
  });
};

export const kickGroupStudent = async (groupId, studentIds) => {
  return await postBackendAlt('group/kick/', {
    id: groupId,
    student_ids: studentIds,
  });
};

export const updateGroup = async (group) => {
  return await postBackendAlt('group/update/', group);
};

///// school
export const getSchool = async (schoolId) => {
  return await getBackend(`school?schoolId=${schoolId}`, true);
};
