export const PURGE_CLIENT = 'PURGE_CLIENT';
export const PURGE_CLIENT_TEMP = 'PURGE_CLIENT_TEMP';

export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAIL = 'GET_CLIENT_FAIL';

export const FIND_CLIENT_SUCCESS = 'FIND_CLIENT_SUCCESS';
export const FIND_CLIENT_FAIL = 'FIND_CLIENT_FAIL';

export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';

export const CREATE_TEACHER_ADMIN_SUCCESS = 'CREATE_TEACHER_ADMIN_SUCCESS';
export const CREATE_TEACHER_ADMIN_FAIL = 'CREATE_TEACHER_ADMIN_FAIL';

export const GET_ALL_STUDENTS_ADMIN_SUCCESS = 'GET_ALL_STUDENTS_ADMIN_SUCCESS';
export const GET_ALL_STUDENTS_ADMIN_FAIL = 'GET_ALL_STUDENTS_ADMIN_FAIL';

export const GET_ALL_TEACHERS_ADMIN_SUCCESS = 'GET_ALL_TEACHERS_ADMIN_SUCCESS';
export const GET_ALL_TEACHERS_ADMIN_FAIL = 'GET_ALL_TEACHERS_ADMIN_FAIL';

export const GET_ALL_MANAGER_TEACHERS_ADMIN_SUCCESS = 'GET_ALL_MANAGER_TEACHERS_ADMIN_SUCCESS';
export const GET_ALL_MANAGER_TEACHERS_ADMIN_FAIL = 'GET_ALL_MANAGER_TEACHERS_ADMIN_FAIL';

export const FIND_TEACHERS_ADMIN_SUCCESS = 'FIND_TEACHERS_ADMIN_SUCCESS';
export const FIND_TEACHERS_ADMIN_FAIL = 'FIND_TEACHERS_ADMIN_FAIL';

export const UPDATE_TEACHER_INFO_ADMIN_SUCCESS = 'UPDATE_TEACHER_INFO_ADMIN_SUCCESS';
export const UPDATE_TEACHER_INFO_ADMIN_FAIL = 'UPDATE_TEACHER_INFO_ADMIN_FAIL';

export const DELETE_CLIENT_ADMIN_SUCCESS = 'DELETE_CLIENT_ADMIN_SUCCESS';
export const DELETE_CLIENT_ADMIN_FAIL = 'DELETE_CLIENT_ADMIN_FAIL';

export const STOP_CLIENT_ADMIN_SUCCESS = 'STOP_CLIENT_ADMIN_SUCCESS';
export const STOP_CLIENT_ADMIN_FAIL = 'STOP_CLIENT_ADMIN_FAIL';

export const REUSE_CLIENT_ADMIN_SUCCESS = 'REUSE_CLIENT_ADMIN_SUCCESS';
export const REUSE_CLIENT_ADMIN_FAIL = 'REUSE_CLIENT_ADMIN_FAIL';

export const CREATE_MANAGER_TEACHER_ADMIN_SUCCESS = 'CREATE_MANAGER_TEACHER_ADMIN_SUCCESS';
export const CREATE_MANAGER_TEACHER_ADMIN_FAIL = 'CREATE_MANAGER_TEACHER_ADMIN_FAIL';

export const CREATE_STUDENT_ADMIN_SUCCESS = 'CREATE_STUDENT_ADMIN_SUCCESS';
export const CREATE_STUDENT_ADMIN_FAIL = 'CREATE_STUDENT_ADMIN_FAIL';